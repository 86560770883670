import '../styles/Footer.css'

function Footer() {
    return ( 
        <div className="footer">
            <p className="first-column-footer">+7 (495) 120-11-25</p>
            <p className="center-column-footer">Секретариат secretary@katusha-it.ru</p>
            <p className="last-column-footer">Основной Telegram @katushait</p>
            <p className="first-column-footer">123112, Москва, Пресненская наб. 8, с.1</p>
            <p className="center-column-footer">IT it@saturn-sw.ru</p>
            <p className="last-column-footer">Поддержка @BestServiceKatushaIT</p>
            <p className="first-column-footer">Москва-Сити, МФК “Город Столиц”</p>
            <p className="center-column-footer">Реклама PR@Katusha-it.ru</p>
            <p className="last-column-footer">YouTube Название</p>
        </div>
    );
}

export default Footer;